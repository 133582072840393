import React from 'react';
import { usePlayer } from './player';
import { CodeNav } from './code-nav';
import classNames from 'classnames';

export const Description: React.FC = () => {
  const { currentFrame } = usePlayer();
  if (!currentFrame) {
    return null;
  }
  const { description } = currentFrame;
  return (
    <div
      className={classNames(
        'bg-white',
        'text-sm',
        'font-sans',
        'flex',
        'flex-col',
        'lg:w-3/12',
        'flex-grow',
        'rounded-t',
        'lg:rounded-tl-none',
        'lg:rounded-r-lg',
      )}
    >
      <div className="px-5 py-3 flex-grow">{description}</div>
      <CodeNav />
    </div>
  );
};
