import React from 'react';
import { Frame, Type } from '../../../src/components/code';
import { BR, I, II } from '../utils';

const description = `The key feature of the store is the dispatch method that
allows any other part of the application to emit an event-like action. When it
is called with an action, it creates a shallow copy of current state...`;

export default () => (
  <Frame description={description}>
    {`export class Store {${BR}`}
    {`${I}constructor(reducers, initialState = {}) {${BR}`}
    {`${II}this._state = initialState; // "Private"${BR}`}
    {`${II}this.reducers = reducers;${BR}`}
    {`${I}}${BR}`}
    <Type>
      {`${BR}`}
      {`${I}dispatch(action) {${BR}`}
      {`${II}let newState = { ...this.state };${BR}`}
      {`${I}}${BR}`}
    </Type>
    {'}'}
    {BR}
    {`const initialState = {};${BR}`}
    {`export const store = new Store(initialState);${BR}`}
  </Frame>
);
