import React, { createContext, useCallback, useContext, useReducer, useRef } from 'react';
import { FrameDefinitionModel, FrameModel } from '../../models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Player {
  currentFrame: FrameModel;
  registerFrame(frameDefinition: FrameDefinitionModel): number;
  hasNextFrame(): boolean;
  hasPrevFrame(): boolean;
  nextFrame(): void;
  previousFrame(): void;
}

const PlayerContext = createContext<Player | undefined>(undefined);

interface PlayerState {
  currentFrame: number;
  frames: Record<FrameModel['id'], FrameModel>;
}

const initialState: PlayerState = {
  currentFrame: 0,
  frames: {},
};

const { reducer, actions } = createSlice({
  name: 'player',
  initialState,
  reducers: {
    register(
      { frames, ...state }: PlayerState,
      { payload }: PayloadAction<FrameModel>,
    ): PlayerState {
      return { ...state, frames: { ...frames, [payload.id]: payload } };
    },
    nextFrame({ currentFrame, ...state }: PlayerState): PlayerState {
      return { ...state, currentFrame: currentFrame + 1 };
    },
    prevFrame({ currentFrame, ...state }: PlayerState): PlayerState {
      return { ...state, currentFrame: currentFrame - 1 };
    },
  },
});

const usePlayerManager = (): Player => {
  const lastFrame = useRef(0);
  const [{ currentFrame, frames }, dispatch] = useReducer(reducer, initialState);
  const registerFrame = useCallback(
    (def: FrameDefinitionModel): number => {
      const id = lastFrame.current++;
      dispatch(actions.register({ ...def, id }));
      return id;
    },
    [lastFrame],
  );
  const hasNextFrame = useCallback(() => currentFrame < lastFrame.current - 1, [currentFrame]);
  const hasPrevFrame = useCallback(() => currentFrame > 0, [currentFrame]);
  const previousFrame = useCallback(
    () => hasPrevFrame() && dispatch(actions.prevFrame()),
    [dispatch, hasPrevFrame],
  );
  const nextFrame = useCallback(
    () => hasNextFrame() && dispatch(actions.nextFrame()),
    [dispatch, hasNextFrame],
  );
  return {
    currentFrame: frames[currentFrame],
    registerFrame,
    nextFrame,
    previousFrame,
    hasNextFrame,
    hasPrevFrame,
  };
};

export const PlayerProvider: React.FC = ({ children }) => {
  const manager = usePlayerManager();
  return <PlayerContext.Provider value={manager}>{children}</PlayerContext.Provider>;
};

export const usePlayer = (): Player => {
  const player = useContext(PlayerContext);
  if (player === undefined) {
    throw new Error('usePlayer must be used within PlayerProvider');
  }
  return player;
};
