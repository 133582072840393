// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-categories-tsx": () => import("./../../../src/pages/blog/categories.tsx" /* webpackChunkName: "component---src-pages-blog-categories-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-tags-tsx": () => import("./../../../src/pages/blog/tags.tsx" /* webpackChunkName: "component---src-pages-blog-tags-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/blog-post.template.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/category.template.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/tag.template.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */)
}

