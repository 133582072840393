import React from 'react';
import { Frame, Type } from '../../../src/components/code';
import { BR, I, II, III } from '../utils';
import Typist from 'react-typist';

const description = `And we close the loop by adding a private notify method
that will be called when the state is updated, and will notify all observers.`;

export default () => (
  <Frame description={description}>
    {`export class Store {${BR}`}
    {`${I}constructor(reducers, initialState = {}) {${BR}`}
    {`${II}this._state = initialState; // "Private"${BR}`}
    {`${II}this.reducers = reducers;${BR}`}
    {`${II}this.observers = [];`}
    {`${I}}${BR}`}
    {`${BR}`}
    {`${I}dispatch(action) {${BR}`}
    {`${II}let newState = { ...this.state };${BR}`}
    {`${II}for (const name in this.reducers) {${BR}`}
    {`${III}const reducer = this.reducers[name];${BR}`}
    {`${III}const slice = this.state[name];${BR}`}
    {`${III}newState[name] = reducer(slice, action);`}
    {`${II}}${BR}`}
    {`${II}this.state = newState;`}
    <Type>{`${II}this.notify(newState);`}</Type>
    {`${I}}${BR}`}
    {BR}
    {`${I}subscribe(observer) {${BR}`}
    {`${II}this.observers.push(observer);${BR}`}
    {`${II}return () => {${BR}`}
    {`${III}this.observers = this.observers.filter(fn => fn !== observer);`}
    {`${II}};${BR}`}
    {`${I}}${BR}`}
    <Type>
      <Typist.Delay ms={500 + 46 * 80} />
      {BR}
      {`${I}notify(nextState) {${BR}`}
      {`${II}this.observers.forEach(${BR}`}
      {`${III}observer => observer(nextState)${BR}`}
      {`${II});${BR}`}
      {`${I}}`}
    </Type>
    {'}'}
    {BR}
    {`const initialState = {};${BR}`}
    {`export const store = new Store(initialState);${BR}`}
  </Frame>
);
