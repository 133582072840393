import React from 'react';
import { Frame } from '../../../src/components/code';
import { BR, I, II, III } from '../utils';

const description = `In this part we'll implement the publisher-subscriber
pattern. Press "Start »" to go to the next step.`;

export default () => (
  <Frame description={description}>
    {`export class Store {${BR}`}
    {`${I}constructor(reducers, initialState = {}) {${BR}`}
    {`${II}this._state = initialState; // "Private"${BR}`}
    {`${II}this.reducers = reducers;${BR}`}
    {`${I}}${BR}`}
    {`${BR}`}
    {`${I}dispatch(action) {${BR}`}
    {`${II}let newState = { ...this.state };${BR}`}
    {`${II}for (const name in this.reducers) {${BR}`}
    {`${III}const reducer = this.reducers[name];${BR}`}
    {`${III}const slice = this.state[name];${BR}`}
    {`${III}newState[name] = reducer(slice, action);`}
    {`${II}}${BR}`}
    {`${II}this.state = newState;`}
    {`${I}}${BR}`}
    {'}'}
    {BR}
    {`const initialState = {};${BR}`}
    {`export const store = new Store(initialState);${BR}`}
  </Frame>
);
