import React, { useEffect } from 'react';
import Prism from 'prismjs';

export const Highlight = React.memo(({ children }) => {
  useEffect(() => Prism.highlightAll(), []);
  return (
    <pre className="m-0 py-0 overflow-visible bg-transparent language-javascript language-jsx">
      <code className="bg-transparent">{children}</code>
    </pre>
  );
});
