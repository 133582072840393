import React from 'react';
import ContentLoader from 'react-content-loader';

export const LazyContentSkeleton: React.FC = () => (
  <ContentLoader
    speed={2}
    width={480}
    height={320}
    viewBox="0 0 480 320"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    className="mx-auto block"
  >
    <rect x="0" y="0" rx="2" ry="2" width="480" height="260" />
    <rect x="80" y="275" rx="2" ry="2" width="320" height="10" />
    <rect x="100" y="295" rx="2" ry="2" width="280" height="10" />
  </ContentLoader>
);
