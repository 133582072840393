import React from 'react';
import { Frame } from '../../../src/components/code';
import { BR } from '../utils';

const description = `In this course we'll implement the Redux Store as a class.
Press "Start »" to go to the next step.`;

export default () => (
  <Frame description={description}>
    {`export class Store {${BR}${BR}`}
    {`}${BR}`}
    {BR}
    {`const initialState = {};${BR}`}
    {`export const store = new Store(initialState);${BR}`}
  </Frame>
);
