import React from 'react';
import { Frame, Type } from '../../../src/components/code';
import { BR, I, II } from '../utils';

const description = `The Store is defined using two pieces of information:
a map of reducers and, optionally, initial state. Reducers are used whenever
an action is dispatched to update the current state.`;

export default () => (
  <Frame description={description}>
    {`export class Store {${BR}`}
    <Type>
      {`${I}constructor(reducers, initialState = {}) {${BR}`}
      {`${II}this._state = initialState; // "Private"${BR}`}
      {`${II}this.reducers = reducers;${BR}`}
      {`${I}}`}
    </Type>
    {`${BR}}`}
    {BR}
    {`const initialState = {};${BR}`}
    {`export const store = new Store(initialState);${BR}`}
  </Frame>
);
