import React from 'react';
import { Frame, Type } from '../../../src/components/code';
import { BR, I, II, III } from '../utils';
import Typist from 'react-typist';

const description = `The reducer is called with the corresponding slice of
the state and the action being dispatched. It returns a new, updated slice or 
the old reference if no changes occurred. Let's store it in the new state.`;

export default () => (
  <Frame description={description}>
    {`export class Store {${BR}`}
    {`${I}constructor(reducers, initialState = {}) {${BR}`}
    {`${II}this._state = initialState; // "Private"${BR}`}
    {`${II}this.reducers = reducers;${BR}`}
    {`${I}}${BR}`}
    {`${BR}`}
    {`${I}dispatch(action) {${BR}`}
    {`${II}let newState = { ...this.state };${BR}`}
    {`${II}for (const name in this.reducers) {${BR}`}
    {`${III}const reducer = this.reducers[name];${BR}`}
    {`${III}const slice = this.state[name];${BR}`}
    <Type>
      {/*<Typist.Delay ms={55 * description.length + 500} />*/}
      {`${III}newState[name] = reducer(slice, action);`}
    </Type>
    {`${II}}${BR}`}
    <Type>
      <Typist.Delay ms={500 + 46 * 80 + 250} />
      {`${II}this.state = newState;`}
    </Type>
    {`${I}}${BR}`}
    {'}'}
    {BR}
    {`const initialState = {};${BR}`}
    {`export const store = new Store(initialState);${BR}`}
  </Frame>
);
