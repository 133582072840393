import React, { useCallback } from 'react';
import Typist from 'react-typist';
import { Highlight } from './highlight';
import Prism from 'prismjs';

export const Type: React.FC = React.memo(({ children }) => {
  const onType = useCallback(() => {
    Prism.highlightAll();
  }, []);
  return (
    <Typist onCharacterTyped={onType} onTypingDone={onType} cursor={{ show: false }}>
      <Highlight>{children}</Highlight>
    </Typist>
  );
});
