import React from 'react';
import { Frame, Type } from '../../../src/components/code';
import { BR, I, II, III } from '../utils';
import Typist from 'react-typist';

const description = `We're adding here a list of observers - functions to be 
called on state change and a \`subscribe\` method to add them.`;

export default () => (
  <Frame description={description}>
    {`export class Store {${BR}`}
    {`${I}constructor(reducers, initialState = {}) {${BR}`}
    {`${II}this._state = initialState; // "Private"${BR}`}
    {`${II}this.reducers = reducers;${BR}`}
    <Type>{`${II}this.observers = [];`}</Type>
    {`${I}}${BR}`}
    {`${BR}`}
    {`${I}dispatch(action) {${BR}`}
    {`${II}let newState = { ...this.state };${BR}`}
    {`${II}for (const name in this.reducers) {${BR}`}
    {`${III}const reducer = this.reducers[name];${BR}`}
    {`${III}const slice = this.state[name];${BR}`}
    {`${III}newState[name] = reducer(slice, action);`}
    {`${II}}${BR}`}
    {`${II}this.state = newState;`}
    {`${I}}${BR}`}
    <Type>
      <Typist.Delay ms={46 * 50} />
      {BR}
      {`${I}subscribe(observer) {${BR}`}
      {`${II}this.observers.push(observer);${BR}`}
      {`${I}}`}
    </Type>
    {'}'}
    {BR}
    {`const initialState = {};${BR}`}
    {`export const store = new Store(initialState);${BR}`}
  </Frame>
);
