import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Highlight } from './highlight';
import { usePlayer } from './player';
import { FrameModel } from '../../models/frame.model';

interface FrameProps {
  description?: string;
  title?: string;
}

type OfArray<T> = T extends Array<infer A> ? A : never;

export const Frame: React.FC<FrameProps> = React.memo(({ children, ...def }) => {
  const frameID = useRef<FrameModel['id'] | undefined>();
  const { currentFrame, registerFrame } = usePlayer();
  useEffect(() => {
    if (frameID.current === undefined) {
      frameID.current = registerFrame(def);
    }
  }, []);
  const mapChild = useCallback((child: OfArray<typeof children>) => {
    if (typeof child === 'string') {
      return <Highlight>{child}</Highlight>;
    }
    return child;
  }, []);
  const content = useMemo(() => React.Children.map(children, mapChild), [children, mapChild]);
  if (currentFrame?.id !== frameID.current) {
    return null;
  }
  return <div className="rounded-t-lg text-sm pt-1 pb-5 mr-3 overflow-x-auto">{content}</div>;
});
Frame.displayName = 'Frame';
