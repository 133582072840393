import React, { useCallback, useEffect } from 'react';
import Prism from 'prismjs';
import 'prism-themes/themes/prism-atom-dark.css';
import { Highlight } from './highlight';
import { PlayerProvider } from './player';
import { Description } from './description';
import { Title } from './title';
import { Header } from './header';

export interface CodeProps {
  dynamic?: boolean;
}

// TODO dynamic import + suspense
export const Code: React.FC<CodeProps> = React.memo(({ children, dynamic }) => {
  const format = useCallback(() => Prism.highlightAll(), []);
  useEffect(() => {
    format();
  });

  if (!dynamic) {
    return (
      <div className="shadow-md rounded-lg my-5 bg-main-darker">
        <Header />
        <div className="text-sm mb-1 py-3 overflow-x-auto">
          <Highlight>{children}</Highlight>
        </div>
      </div>
    );
  }

  return (
    <div className="shadow-md rounded-lg my-5 bg-main-darker flex sm:flex-row flex-col-reverse">
      <PlayerProvider>
        <div className="flex flex-col flex-grow">
          <Header className="hidden sm:block">
            <Title />
          </Header>
          {children}
        </div>
        <Description />
      </PlayerProvider>
    </div>
  );
});
