import { Code } from "../../../../src/components/code";
import { LazyContent } from "../../../../src/components/lazy-content";
import Frame1 from "../../../../content/blog/redux-store/frame-1.tsx";
import Frame2 from "../../../../content/blog/redux-store/frame-2.tsx";
import Frame3 from "../../../../content/blog/redux-store/frame-3.tsx";
import Frame4 from "../../../../content/blog/redux-store/frame-4.tsx";
import Frame5 from "../../../../content/blog/redux-store/frame-5.tsx";
import Frame6 from "../../../../content/blog/redux-store/frame-6.tsx";
import Frame7 from "../../../../content/blog/redux-store/frame-7.tsx";
import Frame8 from "../../../../content/blog/redux-store/frame-8.tsx";
import Frame9 from "../../../../content/blog/redux-store/frame-9.tsx";
import * as React from 'react';
export default {
  Code,
  LazyContent,
  Frame1,
  Frame2,
  Frame3,
  Frame4,
  Frame5,
  Frame6,
  Frame7,
  Frame8,
  Frame9,
  React
};