import React from 'react';
import { usePlayer } from './player';

export const Title: React.FC = () => {
  const { currentFrame } = usePlayer();
  return currentFrame && currentFrame.title ? (
    <p className="inline-block text-main-dark font-sans text-xs align-text-top ml-3">
      {currentFrame.title}
    </p>
  ) : null;
};
