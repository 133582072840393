import React from 'react';
import classNames from 'classnames';
export const Header: React.FC<{ className?: string }> = ({ className, children }) => (
  <div className={classNames('ml-3 pt-1 w-full', className)}>
    <div className="w-3 h-3 mr-1 rounded-full bg-red-500 inline-block" />
    <div className="w-3 h-3 mr-1 rounded-full bg-yellow-500 inline-block" />
    <div className="w-3 h-3 mr-1 rounded-full bg-green-500 inline-block" />
    {children}
  </div>
);
