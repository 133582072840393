import React from 'react';
import { InView } from 'react-intersection-observer';
import { LazyContentSkeleton } from './lazy-content-skeleton';

export const LazyContent: React.FC = ({ children }) => {
  return (
    <InView threshold={1} triggerOnce>
      {({ inView, ref }) => (
        <div ref={ref}>{inView ? <>{children}</> : <LazyContentSkeleton />}</div>
      )}
    </InView>
  );
};
