import React from 'react';
import { usePlayer } from './player';
import { Next, Play, Prev } from '../../assets/icons';

export const CodeNav: React.FC = () => {
  const { nextFrame, previousFrame, hasPrevFrame, hasNextFrame } = usePlayer();
  return (
    <div className="rounded-b-lg px-3 w-full flex-none">
      {hasPrevFrame() && (
        <button className="bg-white hover:bg-gray-100 font-semibold p-2" onClick={previousFrame}>
          <Prev />
        </button>
      )}
      {hasNextFrame() && (
        <button className="bg-white hover:bg-gray-100 font-semibold p-2" onClick={nextFrame}>
          {hasPrevFrame() ? <Next /> : <Play />}
        </button>
      )}
    </div>
  );
};
